<template>
  <div class="h-100">
<!--    <LoadingPage v-if="loadingInProgress" :reporting="loadingReport"/>-->
    <LoadingPageSpinner v-if="loadingInProgress" />
    <slot v-else></slot>
  </div>
</template>

<script>

import loadingList from '@/json/loading.json';
import LoadingPage from "@/components/generic/LoadingPage";
import LoadingPageSpinner from "@/components/generic/LoadingPageSpinner";


export default {
  name: "Preloader",
  mounted() {

    this.startLoading();

  },
  data() {
    return {
      loadingInProgress: true,
      numberOfImagesToLoad: 0,
      imagesLoaded: 0,
      imagesNotFound: 0,
      imagesErrored: 0,
      report: [],
      verbose: true
    }
  },
  components: {
    LoadingPage,
    LoadingPageSpinner
  },
  computed: {
    isMobile() {

      return (window.innerWidth < 992); // 992 is the break point between desktop and mobile.

    },
    arrayOfImagesToLoad() {

      if (this.isMobile) {

        return loadingList.common.concat(loadingList.mobile);

      }

      return loadingList.common.concat(loadingList.desktop);

    },
    loadingReport() {

      if (this.verbose) {

        let htmlString = '';

        htmlString += '<li><b>Mobile mode:' + this.isMobile + '</b></li>';

        this.report.forEach(function (item) {

          htmlString += item;

        });

        return '<ol>' + htmlString + '</ol>';

      }

      return '<p>Files loaded ' + this.imagesLoaded + '/' + this.numberOfImagesToLoad + '</p>';

    },
    numberOfImagesAccountedFor() {

      return this.imagesLoaded + this.imagesNotFound + this.imagesErrored;

    }
  },
  methods: {
    startLoading() {

      this.numberOfImagesToLoad = this.arrayOfImagesToLoad.length;

      let self = this;

      this.arrayOfImagesToLoad.forEach((item) => {
        let image = new Image();
        image.onload = function () {
          self.imageHasLoaded(item)
        };
        image.onerror = function () {
          self.imageLoadingError(item)
        };
        try {

          //image.src = require('@/assets/img/' + item);
          image.src = '/three-assets/' + item;

        } catch (e) {

          console.error('Error: "' + item + '" not found');

          this.imageNotFound(item);

        }

      }, this)


    },
    imageHasLoaded(val) {

      //console.log('Loaded:', val);

      this.imagesLoaded++;

      this.report.push('<li>Loaded: ' + val + '</li>');

      //console.log(this.imagesLoaded + '/' + this.numberOfImagesToLoad + ' loaded.');

      if (this.numberOfImagesAccountedFor === this.numberOfImagesToLoad) {

        this.onAllFilesLoaded();

      }

    },
    imageNotFound(val) {

      this.report.push('<li>Not found: ' + val + '</li>');

      this.imagesNotFound++;

    },
    imageLoadingError(val) {

      this.report.push('<li>Loading error: ' + val + '</li>');

      this.imagesErrored++;

      console.error('Error loading:', val);

    },
    onAllFilesLoaded() {

      console.log('Images are preloaded');

      this.loadingInProgress = false;


    }


  }
}
</script>

<style scoped>

</style>

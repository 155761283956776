import {createStore} from 'vuex'


export default createStore({
    state: {
        GET_SOUND_ENABLED: true,
        START: false,
        SUCCESS: false,
        ARR_FOUND: [],
        TIME_OUT: false,
        LOADED: false
    },
    getters: {},
    mutations: {
        SET_SUCCESS(state, success) {
            state.SUCCESS = success
        },
        SET_START(state, start) {
            state.START = start
        },
        SET_FOUND(state, foundId) {
            state.ARR_FOUND.push(foundId);
        },
        SET_TIME_OUT(state, count) {
            state.TIME_OUT = count
        },
        SET_LOADED(state, boolean) {
            state.LOADED = boolean
        }
    },
    actions: {},
    modules: {

    }
})

<template>
  <div class="w-100 h-100 background-color-1 d-flex justify-content-center align-items-center" >

    <div>
      <div class="lds-spinner" >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {

    //console.log('reporting',this.reporting);

  },
  data() {
    return {
      showReporting: true,
    }
  },
  name: "LoadingPageSpinner",
  props: []
}
</script>

<style scoped>

</style>

<template class="overflow-y-hidden"><!--
  <DevMode v-if="devMode">&lt;!&ndash; Script and lang support stuff -Here be dragons! &ndash;&gt;</DevMode>-->

  <!-- Page stuff in here -->
  <router-view class="overflow-y-hidden"/>
  <Preloader class="overflow-y-hidden"/>
  <!-- Page stuff in here -->
  <!--    <DevPanel/>-->
  <!--  </Preloader>-->
</template>

<script>

import Preloader from "@/components/generic/Preloader.vue"

import {mapGetters, mapMutations, mapState} from "vuex";


export default {

  name: "App",
  mixins: [],
  data() {
    return {
      devMode: false
    }
  },
  mounted() {
  },
  created() {


  },

  components: {
    Preloader
  },
  computed: {
    ...mapState([])
  },
  methods: {
    ...mapMutations([])

  }
}
</script>


<style lang="scss">

nav {
  height: 90px;
}
</style>

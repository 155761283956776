// General functions specific to this site
import {mapGetters, mapState, mapMutations} from "vuex";
import {PauseSpotSFX, PlaySpotSFX} from "@/js/sfx-player";


export default {
    data() {
        return {}
    },
    computed: {
        ...mapState([
            'GET_SOUND_ENABLED'
        ]),
        ...mapGetters([])

    },
    methods: {
        $_PlaySpotFX(val) {
            if (this.GET_SOUND_ENABLED) {
                PlaySpotSFX(val);
            } else {
                console.log('Sound muted', val);
            }
        },

        $_PauseSpotFX(val) {
            if (this.GET_SOUND_ENABLED) {
                PauseSpotSFX(val);
            } else {
                console.log('Sound muted', val);
            }
        }
    }
}

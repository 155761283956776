import {createRouter, createWebHashHistory} from 'vue-router'


const routes = [
    {path: '/', name: 'map', component: () => import('../views/Map.vue')}
]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router

//console.log('%cCREATING SOUND FX', 'background:hotpink;color:white;padding:3px;');

// This makes the iOs versions work better
// https://stackoverflow.com/questions/9811429/html5-audio-tag-on-safari-has-a-delay/54119854#54119854
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();

//audioCtx.resume();

console.log('sfx-player instantiated');

// Preload the audio files.

let soundBank = {
    cacheBuster: new Audio(require('@/assets/audio/SILENCE_DO_NOT_REMOVE.mp3')),
    click1: new Audio(require('@/assets/audio/555545__stwime__wood-block.mp3')),
    success: new Audio(require('@/assets/audio/458398__breviceps__balloon-pop-christmas-cracker-confetti-cannon.wav')),
    music1: new Audio(require('@/assets/audio/6_Music_O_Christmas_Tree_Jazz_Trio_instrumental_2_02.mp3'))
}

export function PlaySpotSFX(id) {

    console.log('%cPlaySpotSFX:' + id, 'color:white;background:red;padding:2px;');

    try {

        // Stop & rewind sound if it is already playing - so it can cope with multiple quick clicks.
        soundBank[id].pause();
        soundBank[id].currentTime = 0;

        soundBank[id].volume = 1;

        //soundBank[id].autoplay = true;

        soundBank[id].play();


    } catch (e) {

        console.error('SFX Player: audio "' + id + '" not found');

        //alert('SFX Player: audio "' + id + '" not found')

    }

}


export function PauseSpotSFX(id) {

    console.log('%cPlaySpotSFX:' + id, 'color:white;background:red;padding:2px;');

    try {

        soundBank[id].pause();


    } catch (e) {

        console.error('SFX Player: audio "' + id + '" not found');

        //alert('SFX Player: audio "' + id + '" not found')

    }

}

<template>
  <div class=" w-100 height-100-percent center" style="background: crimson;color:greenyellow">
    <h1 >LOADING PAGE</h1>
    <div v-if="showReporting" style="font-size: 10px;" v-html="reporting"></div>
  </div>
</template>

<script>
export default {
  created(){

    //console.log('reporting',this.reporting);

  },
  data(){
    return{
      showReporting:true,
    }
  },
  name: "LoadingPage",
  props:['reporting']
}
</script>

<style scoped>

.center {
  margin: auto;
  width: 60%;
  border: 3px solid #73AD21;
  padding: 10px;
}

</style>
